import type { Reviews as ReviewsType } from "@/payload-types";
import { cn } from "~/util/cn";
import Card from "./layout/Card";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: NonNullable<ReviewsType>;
}

const Reviews: React.FC<Props> = ({ items, className, ...props }) => {
  return (
    <div
      {...props}
      className={cn("my-8 flex flex-col items-center gap-8", className)}
    >
      {items.map((item, index) => (
        <Card
          key={index}
          look="funky"
          className={cn(
            "w-lg max-w-full space-y-2",
            ["sm:self-start", "", "sm:self-end"][index % 3],
          )}
        >
          <p className="font-medium italic">{item.content}</p>
          <div>
            {[...Array(item.stars)].map((_, index) => (
              <span key={index} className="text-yellow-400">
                ★
              </span>
            ))}
          </div>
          <div className="text-gray-500">{item.author}</div>
        </Card>
      ))}
    </div>
  );
};
export default Reviews;
