import { cn } from "~/util/cn";
import Heading from "../layout/Heading";
import Gutter from "../layout/Gutter";

export interface TextHeroProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}

const TextHero: React.FC<TextHeroProps> = ({ text, className, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        "bg-gradient-to-b from-white to-gray-100 px-8 py-12 text-center",
        className,
      )}
    >
      <Gutter>
        <Heading className={cn("mx-auto max-w-3xl")}>
          {text.split("\n").map((line, index) => (
            <div
              key={index}
              className={cn(
                index === 0
                  ? "text-blu-500 mb-4 text-3xl"
                  : "text-lg font-medium italic text-gray-800",
              )}
            >
              {line}
            </div>
          ))}
        </Heading>
      </Gutter>
    </div>
  );
};
export default TextHero;
