import type { Page, Product } from "@/payload-types";
import Gutter from "../layout/Gutter";
import React from "react";
import ProductsGrid from "../ProductsGrid";
import Reviews from "../Reviews";
import Pad from "../layout/Pad";
import ContactForm from "../ContactForm";
import TextHero from "./TextHero";
import RichText from "../RichText";

export interface BlockProps extends React.HTMLAttributes<HTMLElement> {
  block?: NonNullable<Page["layout"]>[number];
}

export const Block: React.FC<BlockProps> = ({ block, ...props }) => {
  if (!block) return null;
  switch (block.blockType) {
    case "richText":
      return (
        <Gutter>
          <RichText content={block.content} />
        </Gutter>
      );

    case "products":
      return (
        <Gutter>
          <ProductsGrid
            docs={block.populatedManually ? (block.docs as Product[]) : []}
          />
        </Gutter>
      );

    case "outlet":
      return props.children;

    case "reviews":
      return (
        <Gutter>
          <Reviews items={block.items || []} />
        </Gutter>
      );

    case "spacer":
      return <Pad size={block.size} />;

    case "contactForm":
      return (
        <Gutter>
          <ContactForm formSlug={block.formSlug} />
        </Gutter>
      );

    case "textHero":
      return <TextHero text={block.text} />;

    // this will only occure and be useful when implementing new block types
    default:
      return <div>unimplemented block type</div>;
  }
};

export interface BlocksProps extends React.HTMLAttributes<HTMLDivElement> {
  blocks?: Page["layout"];
}

export const Blocks: React.FC<BlocksProps> = ({
  blocks,
  children,
  ...props
}) => (
  <div {...props}>
    {blocks?.map((block: any, i: number) => (
      <Block key={i} block={block}>
        {children}
      </Block>
    ))}
  </div>
);

export default Blocks;
