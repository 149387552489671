import React, { useEffect, useState } from "react";
import type { action } from "~/routes/api.form-submission";
import { cn } from "~/util/cn";
import Input from "./forms/Input";
import Button from "./layout/Button";
import { useTranslation } from "react-i18next";
import Card from "./layout/Card";
import { useFetcher } from "@remix-run/react";
import Turnstile from "react-turnstile";
import { useEnv } from "~/util/useEnv";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  formSlug: string;
};

export const ContactForm: React.FC<Props> = ({
  formSlug,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const { Form, state, data } = useFetcher<typeof action>();
  const env = useEnv();

  const zodError = data?.zodError ? JSON.parse(data.zodError) : null;

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Card
      {...props}
      className={cn("grid max-w-xl grid-cols-1 gap-4", className)}
      look="gray"
    >
      {data?.ok ? (
        <div className="h-100 flex flex-col items-center justify-center gap-8 text-center font-medium">
          <div className="i-solar:chat-round-check-linear text-yello-500 p-10 text-4xl" />
          {t("contact.success")}
        </div>
      ) : (
        <Form
          action="/api/form-submission"
          method="post"
          className="grid grid-cols-1 gap-4"
          noValidate
        >
          <input type="hidden" name="formSlug" value={formSlug} />
          <Input
            name="name"
            label={t("contact.label.name")}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          <Input
            type="email"
            name="email"
            label={t("contact.label.email")}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          <Input
            type="tel"
            name="phone"
            label={t("contact.label.phone")}
            readOnly={state === "loading"}
            zodError={zodError}
          />
          <Input
            tag="textarea"
            name="message"
            label={t("contact.label.message")}
            zodError={zodError}
            readOnly={state === "loading"}
            required
          />
          {isClient && (
            <Turnstile
              sitekey={env?.TURNSTILE_SITE_KEY ?? ""}
              execution="render"
              fixedSize
              theme="light"
              className="mb-2"
            />
          )}
          <Button
            type="submit"
            look={"yello"}
            className="w-full"
            disabled={state === "loading"}
          >
            {t(state === "idle" ? "contact.submit" : "contact.submitting")}
          </Button>
          {data?.ok === false && !zodError && (
            <div className="rounded border border-red-200 bg-red-100 p-4">
              {t("contact.error.submit")}
            </div>
          )}
        </Form>
      )}
    </Card>
  );
};

export default ContactForm;
